import React from "react";
import { graphql } from "gatsby";

import Wrap from "../components/wrap";
import SEO from "../components/seo";

import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import MdxLink from "../components/mdxLink";

const Post = ({ data: { mdx } }) => (
  <>
    <SEO title={mdx.frontmatter.title} />
    <div className="xl:bg-leaf-3">
      <Wrap maxWidth="max-w-2xl c-markdown">
        <h1>{mdx.frontmatter.title}</h1>
        <p className="c-caps text-jungle-300">
          {mdx.frontmatter.location} &mdash; {mdx.frontmatter.date}
        </p>

        <hr className="mb-8" />

        <div className="c-markdown">
          <MDXProvider
            components={{
              a: MdxLink,
            }}
          >
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </div>
      </Wrap>
    </div>{" "}
    */}
  </>
);

export default Post;

export const query = graphql`
  query Post($title: String!, $locale: String!) {
    mdx(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        location
        date
      }
      body
    }
  }
`;
